<template>
  <div>
    <ReportCellsHead
        :countFilterParams="countFilterParams"
        :showFilter="showFilter"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">

      <ReportCellsFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          :countFilterParams="countFilterParams"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <ReportCellsTable
          :countFilterParams="countFilterParams"
          :filterGetParams="filterGetParams"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @updateTable="$emit('updateTable')"
          @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


  import ReportCellsHead from "./ReportCellsHead/ReportCellsHead";
  // import ReportCellsFilter from "./ReportCellsFilter/ReportCellsFilter";
  import ReportCellsTable from "./ReportCellsTable/ReportCellsTable";
  import ReportCellsFilter
    from "@/components/modules/ReportCellsModule/components/ReportCellsTable/ReportCellsTableAdmin/ReportCellsFilter/ReportCellsFilter";

  export default {
    name: "ReportCellsTableUser",

    components: {
      ReportCellsFilter,
      ReportCellsHead,
      // ReportCellsFilter,
      ReportCellsTable,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },
    }
  }
</script>

<style scoped>

</style>
