<template>
  <ReportCellsTableAdmin
      v-if="isAdmin"
      :countFilterParams="countFilterParams"
      :filterGetParams="filterGetParams"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @changeFilter="changeFilter"
      @reload="reload"
  />
</template>

<script>
  import ReportCellsTableAdmin from "./ReportCellsTableAdmin/ReportCellsTableAdmin";
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";

  export default {
    name: "ReportCellsTable",
    components: {
      ReportCellsTableAdmin,
    },

    mixins: [routeFilter],

    data(){
      return {
        forPage: this.$store.getters.getCellsReportForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterUserId: '',
        filterUserName: '',
        filterUPC: '',
        filterSKU: '',
        filterCellName: '',
        filterProductId: '',
        filterProductName: '',
        filterStorage: '',

        filterShipped: [],

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    mounted() {
      this.filter()
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          id: this.$route.query.id,
          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
          cellName: this.$route.query.cellName,
          UPC: this.$route.query.UPC,
          SKU: this.$route.query.SKU,
          productId: this.$route.query.productId,
          productName: this.$route.query.productName,
          storage: this.$route.query.storage,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextCellsReportPage', true)
        this.$store.dispatch('fetchCellsReport', url).then(() => {
          this.$store.commit('setNextCellsReportPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })





        this.checkCountFilter(['userName','productName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()

        this.generateFilterQueryParams(
            myQuery,
            {
              filterId: 'id',
              filterUserId: 'product_user_id',
              filterCellName: 'cell_name',
              filterUPC: 'upc_code',
              filterSKU: 'sku_code',
              filterProductId: 'product_id',
              filterStorage: 'byStorageId',
            },
        )


        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    },

    destroyed() {
      setTimeout(() => {
        this.$store.commit('setCellsReport', {cellsReport: []})
        this.$store.commit('setCellsReportCommonList', {cellsReportCommonList: []})
      }, 300)
    },


  }
</script>

<style scoped>

</style>

<!--fetchCellsReport-->