<template>
  <div class="fragment">
    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">

      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-right-block">
        <MainButton
            :value="'Print'"
            @click.native="printTable"
        />

        <MainButton class="btn-fit-content ml-3 wfc white-space-nowrap"
                    :value="$t('reportCells_getFreeCells.localization_value.value')"
                    :ico="'ico'"
                    @click.native="changeFeeCellsPopup(true)"
        >
        </MainButton>
      </div>
    </div>

<!--    <div class="content-top-line">-->
<!--      <div class="report-cells-search">-->
<!--        <div class="report-cells-search__item">-->
<!--          <SearchInput-->
<!--              :type="'text'"-->
<!--              :label="'Search'"-->
<!--              v-model="search"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->

<!--    </div>-->

    <FreeCellsPopup
        v-if="freeCellsPopup"
        @close="changeFeeCellsPopup(false)"
    />
  </div>

</template>



<script>


  // import SearchInput from "../../../../../../UI/inputs/SearchInput/SearchInput";
  import ToggleFilterButton from "@/components/UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";
  import FreeCellsPopup from "@/components/modules/ReportCellsModule/popups/FreeCellsPopup/FreeCellsPopup";

  export default {
    name: "ReportCellsHead",
    components: {
      FreeCellsPopup,
      MainButton,
      ToggleFilterButton,
      // SearchInput,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    data() {
      return {
        freeCellsPopup: false,
      }
    },

    methods: {
      changeFeeCellsPopup(val) {
        this.freeCellsPopup = val
      },

      printTable() {
        window.print()
      },
    }

  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";

  .report-cells-search{
    display: flex;
    justify-content: flex-end;
    width: 100%;

    &__item{
      max-width: 314px;
      width: 100%;

      @include for-680{
        max-width: 100%;
      }
    }
  }
</style>


<style media="print">

@page { size: portrait; }

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
  body .noPrintable {
    display: none;
  }

  body .onlyPrintable {
    display: block !important;
  }

  .default-layout__page-wrap{
    padding-left: 0 !important;
  }
  .manager-btn{
    display: none !important;
  }

  .max-200{
    max-width: 200px !important;
  }

  .max-40{
    max-width: 40px !important;
    width: 40px !important;
  }

  .header__inner{
    display: none !important;
  }

  .default-layout__page-content{
    margin-top: 30px;
  }

  .site-table-wrap.table-small,
  .site-table {
    /*width: initial !important;*/
  }


  .site-table-wrap.table-small th{
    width: initial !important;
  }

  .site-table-wrap td:nth-child(7),
  .site-table-wrap th:nth-child(7){
    max-width: 50px !important;
    min-width: 50px !important;
  }

  .site-table-wrap td:nth-child(5) div{
    max-width: 150px !important;
    min-width: 50px !important;
  }

  /*.site-table-wrap.table-small td:nth-child(3),*/
  /*.site-table-wrap.table-small th:nth-child(3){*/
  /*  width: 200px !important;*/
  /*  !*background: red;*!*/
  /*}*/

  /*.site-table-wrap.table-small td:nth-child(4),*/
  /*.site-table-wrap.table-small th:nth-child(4){*/
  /*  width: 200px !important;*/
  /*}*/

  .order-admin-products .site-table-wrap{
    width: initial !important;
    max-width: initial!important;
    overflow: hidden !important;
  }

  .site-table{
    width: initial !important;
    max-width: initial!important;
    overflow: hidden !important;
  }

  .site-table-wrap.table-small td:first-child,
  .site-table-wrap.table-small td:last-child,
  .site-table-wrap.table-small th:first-child,
  .site-table-wrap.table-small th:last-child{
    display: none;
  }

  .search-engine-block__inner{
    display: none !important;
  }

}
</style>
