<template>
  <div class="table-filter-wrap">

    <template
        v-if="$store.getters.getCellsReport.length > 0 && $store.getters.getCellsReportLoading !== true">
      <div class="site-table-wrap small-table scrolling-macbook-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'reportCells_ID',
            'reportCells_User',
            'reportCells_SKU',
            'reportCells_UPC',
            'reportCells_ProductName',
            'reportCells_Cell',
            'reportCells_ItemsQty',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <!--            <th></th>-->
            <th>{{ $t('reportCells_ID.localization_value.value') }}</th>
            <th>{{ $t('reportCells_User.localization_value.value') }}</th>
            <th>{{ $t('reportCells_SKU.localization_value.value') }}</th>
            <th>{{ $t('reportCells_UPC.localization_value.value') }}</th>
            <th>{{ $t('reportCells_ProductName.localization_value.value') }}</th>
            <th>{{ $t('reportCells_Cell.localization_value.value') }}</th>
            <th>{{ $t('reportCells_ItemsQty.localization_value.value') }}</th>
            <th width="100%"></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(cell, indexCell) in $store.getters.getCellsReport">
            <tr v-for="(product, indexProduct) in cell.products" :key="indexCell + 'p' + indexProduct">
              <template
                  v-if="showWithFilter(product) && showWithFilterSKU(product) && showWithFilterUPC(product)">
                <td>
                  <router-link
                      :to="$store.getters.GET_PATHS.products + '?id=' + product.id"
                      class="table-link btn-style">
                    #{{ product.id }}
                  </router-link>
                </td>
                <td>
                  <TableUserColumn
                      :item="product"
                  />
                </td>
                <td>
                  <ValueHelper
                      :value="product.upc_code"
                  />
                </td>
                <td>
                  <ValueHelper
                      :value="product.sku_code"
                  />
                </td>
                <td>
                  <div class="table-row">
                    <div style="max-width: 450px; min-width: 450px; white-space: initial">
                      {{product.name_for_fba}}
                    </div>
<!--                    <TooltipTextHelper-->
<!--                        :text="product.name_for_fba"-->
<!--                        :paragraph="product.name_for_fba"-->
<!--                        :fullTextWidth="'450'"-->
<!--                    />-->
                  </div>
                </td>
                <td>
                  {{ cell.combo_name }}
                </td>
                <td>
                  {{ product.itemsInCell.count_normal }}
                </td>
                <td width="100%">
                  <div class="d-flex justify-content-end pr-3">
                    <LinkButton
                        :value="$t('reportCells_show.localization_value.value')"
                        :type="false"
                        :link="$store.getters.GET_PATHS.financeReportCells + '/' + cell.id + '/' + product.id"
                        :target="'_blank'"
                    />
                  </div>
                </td>
              </template>

            </tr>
          </template>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <template v-for="(cell, indexCell) in $store.getters.getCellsReport">
              <div class="table-card__item"
                   v-for="(product, indexProduct) in cell.products" :key="indexCell + 'p' + indexProduct"
                   v-bind:class="{'d-none': !showWithFilter(product) || !showWithFilterSKU(product) || !showWithFilterUPC(product)}"
              >
                <ReportCellsTableMobile
                    :cell="cell"
                    :product="product"
                />
              </div>
            </template>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getCellsReportCommonList.next_page_url !== null && $store.getters.getCellsReport.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextCellsReportPage}"
                :count="$store.getters.getCellsReportCommonList.total - $store.getters.getCellsReportForPage < $store.getters.getCellsReportForPage ?
                    $store.getters.getCellsReportCommonList.total - $store.getters.getCellsReportForPage:
                    $store.getters.getCellsReportForPage"
            />
          </div>
        </div>
      </div>
    </template>

    <template
        v-if="$store.getters.getCellsReportLoading === false && $store.getters.getCellsReport.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>


  </div>
</template>

<script>
import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import NoResult from "@/components/coreComponents/NoResult/NoResult";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import ReportCellsTableMobile
  from "@/components/modules/ReportCellsModule/components/ReportCellsTable/ReportCellsTableAdmin/ReportCellsTable/ReportCellsTableMobile/ReportCellsTableMobile";


export default {
  name: "ReportCellsTable",

  components: {
    ReportCellsTableMobile,
    ValueHelper,
    NoResult,
    TableUserColumn,
    ShowMore,
    LinkButton,
  },

  mixins: [mixinDetictingMobile],

  props: {
    countFilterParams: Number,
    filterGetParams: Object,
  },


  methods: {

    showWithFilter(product) {

      if (this.filterGetParams.userId === undefined && this.filterGetParams.productId === undefined) return true

      if (this.filterGetParams.productId === undefined && (
          product.user.id === parseInt(this.filterGetParams.userId)
      )) return true

      if (this.filterGetParams.userId === undefined && product.id === parseInt(this.filterGetParams.productId))
        return true

      if (product.user.id === parseInt(this.filterGetParams.userId) &&
          product.id === parseInt(this.filterGetParams.productId))
        return true

      return false
    },

    showWithFilterSKU(product) {
      if (this.filterGetParams.SKU === undefined) return true

      if (product.sku_code.indexOf(this.filterGetParams.SKU) > -1)
        return true

      return false
    },

    showWithFilterUPC(product) {
      if (this.filterGetParams.UPC === undefined) return true

      if (product.upc_code.indexOf(this.filterGetParams.UPC) > -1)
        return true

      return false
    },
  }

}

</script>

<style scoped lang="scss">
.comment-row {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 330px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.table-manage-list {
  width: 230px;

  .manager-btn {
    max-width: 230px;
  }
}


@media (max-width: 1900px) {
  .comment-row {
    width: 200px;
  }
}

@media (max-width: 1400px) {
  .comment-row {
    width: 150px;
  }
}

.site-table thead th:first-child,
.site-table tbody td:first-child {
  padding-right: 0;
}

.site-table thead th:last-child,
.site-table tbody td:last-child {
  padding-right: 0;
}

.small-table th,
.small-table td {
  white-space: nowrap;
}

</style>
