<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['reportCells_cellID'])"></span>
        <DefaultInput
            :label="$t('reportCells_cellID.localization_value.value')"
            :type="'text'"
            v-model="id"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['reportCells_User'])"></span>

        <SearchSelect
            :options="options"
            :label="$t('reportCells_User.localization_value.value')"
            :functionSearch="functionSearchUserFilter"
            :selected="userName"
            :userEmail="true"
            :typeSelect="'users'"
            @change="changeUsersFilter"
        />
      </div>

<!--      <div class="table-filter__item"-->
<!--           v-if="isAdmin"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['reportCells_ProductName'])"></span>-->

<!--        <DefaultSelect-->
<!--            :options="products"-->
<!--            :label="$t('reportCells_ProductName.localization_value.value')"-->
<!--            :userEmail="true"-->
<!--            :optionsLabel="'name'"-->
<!--            :selected="productId === '' ? '' : {-->
<!--              id: productId,-->
<!--              name: productName-->
<!--            }"-->
<!--            @change="changeProductsFilter"-->
<!--        />-->
<!--      </div>-->

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['reportCells_Cell'])"></span>
        <DefaultInput
            :label="$t('reportCells_Cell.localization_value.value')"
            :type="'text'"
            v-model="cellName"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['reportCells_UPC'])"></span>
        <DefaultInput
            :label="$t('reportCells_UPC.localization_value.value')"
            :type="'text'"
            v-model="UPC"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['reportCells_SKU'])"></span>
        <DefaultInput
            :label="$t('reportCells_SKU.localization_value.value')"
            :type="'text'"
            v-model="SKU"
        />
      </div>

      <div class="table-filter__item">
        <DefaultSelect
            class="w-100"
            :optionsLabel="'name'"
            :options="optionsStorage"
            :label="$t('productsReport_Storage.localization_value.value')"
            :selected="_.find(optionsStorage, {id: parseInt(storage)})"
            @change="changeStorageFilter"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "@/mixins/usersMixins/usersFunc";
  import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
  // import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "ReportCellsFilter",
    components: {
      DefaultSelect,
      // DefaultSelect,
      SearchSelect,
      FilterBlock,
      MainButton,
      DefaultInput,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        // date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        cellName: this.filterGetParams.cellName ? this.filterGetParams.cellName : '',
        UPC: this.filterGetParams.UPC ? this.filterGetParams.UPC : '',
        SKU: this.filterGetParams.SKU ? this.filterGetParams.SKU : '',
        productId: this.filterGetParams.productId ? this.filterGetParams.productId : '',
        productName: this.filterGetParams.productName ? this.filterGetParams.productName : '',

        storage: this.filterGetParams.storage ? this.filterGetParams.storage : '',

        filterCounts: [
          'id',
          'userId',
          'userName',
          'cellName',
          'UPC',
          'SKU',
          'productId',
          'productName',
          'storage',
        ],

        options: [],
        products: [],
        optionsStorage: [],
      }
    },

    watch: {
      filterGetParams(newVal) {
        // this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.id = newVal.id ? newVal.id : ''
        this.userId = newVal.userId ? newVal.userId : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.cellName = newVal.cellName ? newVal.cellName : ''
        this.UPC = newVal.UPC ? newVal.UPC : ''
        this.SKU = newVal.SKU ? newVal.SKU : ''
        this.productId = newVal.productId ? newVal.productId : ''
        this.productName = newVal.productName ? newVal.productName : ''
        this.storage = newVal.storage ? newVal.storage : ''
      },

    },

    mounted() {
      // const query = new this.Query();
      // let url = '?';
      // let myQuery = query
      //     .for('posts')
      //
      // query.where('withNormalItemsInStorage', '1')
      //
      // url = url + myQuery.limit(100000).page(parseInt(0)).url().split('?')[1]
      //
      // this.$store.dispatch('fetchProducts', url).then(response => {
      //   this.products = this.getRespPaginateData(response)
      // })
      this.getWarehouseStorages()
    },

    methods: {

      async getWarehouseStorages() {
        return this.$store.dispatch('fetchWarehouseStorage').then((response) => {
          this.optionsStorage = this.getRespPaginateData(response)
        })
      },

      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeUsersFilter(value) {
        if(value === ''){
          this.userId = ''
          this.userName = ''
          return
        }
        this.userId = value.id
        this.userName = value.contacts[0].user_full_name
      },

      changeProductsFilter(val) {
        this.productId = val.id
        this.productName = val.name
      },

      changeStorageFilter(val) {
        this.storage = val.id
      },
    },
  }
</script>

<style scoped>

</style>
