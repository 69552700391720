<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['reportCells_freeCells'])"></div>
        {{$t('reportCells_freeCells.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="transaction-info__content">
        <div class="transaction-info__section">


          <div class="transaction-info__row">
            <div class="transaction-info__col w-100">
<!--              <div class="transaction-info__label mb-3"-->
<!--                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['reportCells_cells'])"></div>-->
<!--                {{$t('reportCells_cells.localization_value.value')}}-->
<!--              </div>-->
              <template v-if="cells.length === 0">
                —
              </template>
              <div class="transaction-info__txt">
                <div class="custom-row mb-3"
                     v-for="(cell, index) in cells"
                     :key="index">
                  <div class="custom-col mb-0">
                    {{cell.combo_name}}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'


  export default {
    name: "FreeCellsPopup",
    components: {
      Modal,
    },

    data() {
      return {
        cells: [],
      }
    },

    mounted() {

      let filter = `?page=0&limit=10000&filter[isFree]=1`
      this.$store.dispatch('fetchCellsReportWithoutCommit', filter).then((response) => {
        this.cells = this.getRespPaginateData(response)
      })
    },

    methods: {


    }

  }

</script>

<style lang="scss" scoped>
  .site-document__img{
    min-width: 65px;
  }
</style>
