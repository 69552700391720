<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <router-link
            :to="$store.getters.GET_PATHS.products + '?id=' + product.id"
            class="table-card__item-number table-card__item-number--small">
          #{{ product.id }}
        </router-link>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('reportCells_ID.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              #{{ product.id }}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('reportCells_User.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                  :item="product"
                  :mobile="true"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('reportCells_UPC.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="product.upc_code"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('reportCells_SKU.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="product.sku_code"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('reportCells_ProductName.localization_value.value') }}
            </div>
            <div class="table-card__item-info text-break">
              <ValueHelper
                  :value="product.name_for_fba"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('reportCells_Cell.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ cell.combo_name }}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('reportCells_ItemsQty.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ product.itemsInCell.count_normal }}
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('reportCells_show.localization_value.value')"
                :type="false"
                :link="$store.getters.GET_PATHS.financeReportCells + '/' + cell.id + '/' + product.id"
                :target="'_blank'"
            />
          </div>
        </div>

      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{ $t('common_showMore.localization_value.value') }}
      </div>
    </div>
  </div>
</template>

<script>
import TableUserColumn from "@/components/coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
export default {
  name: "ReportCellsTableMobile",

  components: {ValueHelper, LinkButton, TableUserColumn},

  props: {
    cell: Object,
    product: Object,
  },

  data() {
    return {
      show: false,
    }
  },

  methods: {

  },

}
</script>

<style scoped>

</style>